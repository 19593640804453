import React, { useState } from "react";
import "./Header.css";
import logo from "../../assets/logo.png";
import Bars from "../../assets/bars.png";
import { Link } from "react-scroll";

const Header = () => {
  const mobile = window.innerWidth <= 768 ? true : false;
  const [baaar, setBaaar] = useState(false);
  return (
    <div className="header">
      <img src={logo} alt="" className="logo" />
      {baaar === false && mobile === true ? (
        <div
          style={{
            backgroundColor: "var(--appColor)",
            padding: "0.3rem",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => {
            setBaaar(true);
          }}
        >
          <img
            src={Bars}
            alt=""
            style={{ width: "1.5rem", height: "1.5rem336" }}
          />
        </div>
      ) : (
        <ul className="h-menu">
          <li>
            <Link
              onClick={() => setBaaar(false)}
              activeClass="active"
              to="hero"
              spy={true}
              smooth={true}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              onClick={() => setBaaar(false)}
              to="programs"
              smooth={true}
              spy={true}
            >
              Program
            </Link>
          </li>
          <li>
            <Link
              onClick={() => setBaaar(false)}
              to="Why"
              smooth={true}
              spy={true}
            >
              Why Us
            </Link>
          </li>
          <li>
            <Link
              onClick={() => setBaaar(false)}
              to="Plan"
              smooth={true}
              spy={true}
            >
              Plans
            </Link>
          </li>
          <li>
            <Link
              onClick={() => setBaaar(false)}
              to="testimonial"
              spy={true}
              smooth={true}
            >
              Testinomials
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
};

export default Header;
