import "./App.css";
import React from "react";
import Footer from "./Components/Footer/Footer";
import Hero from "./Components/Hero/Hero";
import Join from "./Components/Join/Join";
import Plan from "./Components/Plans/Plan";
import Program from "./Components/Programs/Program";
import Testimonial from "./Components/Testinomial/Testimonial";
import Why from "./Components/Why US/Why";
function App() {
  return (
    <div className="App">
      <React.StrictMode>
        <Hero />
        <Program />
        <Why />
        <Plan />
        <Testimonial />
        <Join />
        <Footer />
      </React.StrictMode>
    </div>
  );
}

export default App;
