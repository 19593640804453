import React from "react";
import "./Join.css";

const Join = () => {
  return (
    <div className="Join-container">
      <div className="l-side">
        <hr />
        <div>
          <span className="stroke-text">ready to </span>
          <span>level up</span>
        </div>
        <div>
          <span>your body </span>
          <span className="stroke-text">with us</span>
        </div>
      </div>
      <div className="r-side">
        <form action="" className="email-container">
          <input
            type="email"
            name="user-email"
            placeholder="Enter your E-mail Address"
          />
          <button className="btn btn-j">Join now</button>
        </form>
      </div>
    </div>
  );
};

export default Join;
