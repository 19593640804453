import React from "react";
import "./Why.css";
import Nike from "../../assets/nike.png";
import Tick from "../../assets/tick.png";
import image1 from "../../assets/image1.png";
import image2 from "../../assets/image2.png";
import image3 from "../../assets/image3.png";
import image4 from "../../assets/image4.png";
import nb from "../../assets/nb.png";
import Adidas from "../../assets/adidas.png";

const Why = () => {
  return (
    <div className="why" id="Why">
      <div className="left-side">
        <img src={image1} alt="first" />
        <img src={image2} alt="second" />
        <img src={image3} alt="third" />
        <img src={image4} alt="fourth" />
      </div>
      <div className="right-side">
        <span>Some Reason</span>
        <div>
          <span className="stroke-text">Why </span>
          <span>Choose us ?</span>
        </div>
        <div className="details-r">
          <div>
            <img src={Tick} alt="" />
            <span>over 140+ Expert coaches</span>
          </div>
          <div>
            <img src={Tick} alt="" />
            <span>train smarter and faster than before</span>
          </div>
          <div>
            <img src={Tick} alt="" />
            <span>1 free program for new member</span>
          </div>
          <div>
            <img src={Tick} alt="" />
            <span>reliable partner</span>
          </div>
        </div>
        <span style={{ color: "var(--gray)", fontWeight: "normal" }}>
          Our Partners
        </span>
        <div className="partnerrs">
          <img src={nb} alt="" />
          <img src={Adidas} alt="" />
          <img src={Nike} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Why;
