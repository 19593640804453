import React from "react";
import "./Program.css";
import "../../data/programsData";
import { programsData } from "../../data/programsData";
import rightArrow from '../../assets/rightArrow.png'

const Program = () => {
  return (
    <div className="Programs" id="programs">
      <div className="program-header">
        <span className="stroke-text">Explore Our</span>
        <span>Programs</span>
        <span className="stroke-text">To Shape You </span>
      </div>
      <div className="programCat">
        {programsData.map((program) => {
          return (
            <div className="catogery">
              {program.image}
              <span>{program.heading}</span>
              <span>{program.details}</span>
              <div className="join-now">
                <span>Join now</span>
                <img src={rightArrow} alt="" />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Program;
