import React from "react";
import "./Plan.css";
import { plansData } from "../../data/plansData";
import whiteTick from "../../assets/whiteTick.png";

const Plan = () => {
  return (
    <div className="plans-container" id="Plan">
      <div className="blur blur-l"></div>
      <div className="blur blur-r"></div>
      <div className="plan-header">
        <span className="stroke-text">Ready to start</span>
        <span>your journey</span>
        <span className="stroke-text">now with us</span>
      </div>
      <div className="pkgs">
        {plansData.map((pkg, i) => {
          return (
            <div className="pkg" key={i}>
              {pkg.icon}
              <span>{pkg.name}</span>
              <span>{pkg.price}</span>
              <div className="features">
                {pkg.features.map((feature, i) => {
                  return (
                    <div className="feature">
                      <img src={whiteTick} alt="" />
                      <span key={i}>{feature}</span>
                    </div>
                  );
                })}
              </div>
              <div>
                <span>See more benefits -&gt;</span>
              </div>
              <button className="btn">Join Now</button>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Plan;
